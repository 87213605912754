<template>
      <div class="inventoryReservationEdit">
        <v-breadcrumbs>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'home', params: {} }"
            >Home</v-breadcrumbs-item
          >
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :exact="true" :to="{ name: 'inventoryReservation', params: {} }">
          Inventory Reservation
          </v-breadcrumbs-item>
          <v-breadcrumbs-divider>/</v-breadcrumbs-divider>
          <v-breadcrumbs-item :to="{ name: 'inventoryReservationEdit', params:{id: id} }">
            Edit Inventory Reservation
          </v-breadcrumbs-item>
        </v-breadcrumbs>

        <v-card>
          <v-card-title>Edit Inventory Reservation</v-card-title>
          <v-card-text>
            <inventoryReservation-form :id="id"></inventoryReservation-form>
          </v-card-text>
        </v-card>
      </div>
    </template>

    <script>
    import InventoryReservationForm from './InventoryReservationForm.vue';

    export default {
      name: 'inventoryReservation',
      data () {
        return {
          id: null
        }
      },
      components: {
        InventoryReservationForm
      },
      created () {
          this.id = this.$route.params.id;
          this.$emit("showParent", false);
      }
    }
    </script>
    